import Vue from 'vue'
import App from './App.vue'

// import '@/utils/encryption.js'



import router from './router'
import store from './store'


import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

import HeaderComponent from './components/header'
import FooterComponent from './components/footer'
import UseInputComponent from './components/useInput'
import UseTooltip from './components/useTooltip'
import UseDialog from './components/useDialog'

// 全局注册组件
Vue.component('HeaderComponent', HeaderComponent)
Vue.component('FooterComponent', FooterComponent)
Vue.component('UseInputComponent', UseInputComponent)
Vue.component('UseTooltip', UseTooltip)
Vue.component('UseDialog', UseDialog)


import mixins from './mixins'

Vue.mixin(mixins);  // 注册全局 Mixin

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


Vue.use(ElementUI)

// 创建一个封装的 Message 方法
const defaultMessage = options => {
    // 如果 options 是字符串，则作为 message 内容
    if (typeof options === 'string') {
        options = {
            message: options
        }
    }
    // 设置默认显示时间
    options.duration = options.duration || 1500
    return ElementUI.Message(options)
}

// 挂载到 Vue 原型
Vue.prototype.$message = defaultMessage

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
