import service from "@/utils/request";

//游戏配置
export let gameConfigApi = (params) => {
    return service.get('/gameApi/config', {params})
}

//游戏配置计时器
export let gameConfigTimeOutApi = (params) => {
    return service.get('/gameApi/config?timeOut=1', {params})
}

//登录
export let gameLoginApi = (params) => {
    return service.post('/gameLogin', params)
}


//刷新token
export let refreshTokenApi = (params) => {
    return service.post('/refreshToken', params)
}

//获取用户信息
export let getUserInfoApi = (params) => {
    return service.get('/v1/gameApi/game/userinfo', {params})
}

//获取我的游戏  盈亏  注单笔数
export let getUserGameInfo = (params) => {
    return service.get('/gameApi/user/game/info', {params})
}

//获取图片验证码
export let getCaptchaApi = (params) => {
    return service.get('/getCaptcha', {params})
}

//注册
export let gameApiRegisterApi = (params) => {
    return service.post('/gameApi/register', params)
}

//获取用户信息计时器
export let getUserInfoTimeOutApi = (params) => {
    return service.get('/v1/gameApi/game/userinfo?timeOut=1', {params})
}

//游戏定位
export let sendPositionTimeOutApi = (params) => {
    return service.post('/gameApi/position?timeOut=1', params)
}

//获取公告
export let getAllMessageApi = (params) => {
    return service.get('/gameApi/getAllMessage', {params})
}

//首页列表
export let getHomeListApi = (params) => {
    return service.get('/v1/gameApi/game/tag/list', {params})
}

//获取官方游戏登录链接
export let getGameUrlMainApi = (params) => {
    return service.get('/v1/gameApi/game/url/main', {params})
}

//获取第三方游戏登录链接
export let getGameUrlApi = (params) => {
    return service.get('/v1/gameApi/game/url', {params})
}

//获取登录链接
export let getGameTransLoginApi = (params) => {
    return service.post('/v1/gameApi/game/trans/login', params)
}

//把余额转入平台
export let gameUserTransApi = (params) => {
    return service.post('/v1/gameApi/game/user/trans', params)
}

//余额提回平台
export let gameTransBackApi = (params) => {
    return service.post('/v1/gameApi/game/trans/back', params)
}

//优惠
export let getGamePromoApi = (params) => {
    return service.get('/v1/gameApi/game/promo', params)
}

//充值记录  存款记录
export let getRecordPayApi = (params) => {
    return service.get('/gameApi/record/pay', {params})
}

//取款记录
export let getRecordCashOutApi = (params) => {
    return service.get('/gameApi/record/cashOut', {params})
}

//转账记录
export let getRecordTransferApi = (params) => {
    return service.get('/v1/gameApi/popularize/record/transfer', params)
}

//返佣记录
export let getRecordReturnApi = (params) => {
    return service.get('/v1/gameApi/popularize/record/return', params)
}

//投注记录
export let getGameRecordApi = (params) => {
    return service.get('/gameApi/getGameRecord', {params})
}

//额度记录  资金变动
export let getGoldDetailApi = (params) => {
    return service.get('/gameApi/getGoldDetail', {params})
}

//总盈亏
export let getWinTotalApi = (params) => {
    return service.get('/gameApi/getWinTotal', {params})
}

//场馆盈亏
export let getCategoryWinTotalApi = (params) => {
    return service.get('/gameApi/getCategoryWinTotal', {params})
}

//获取消息
export let getMessageListApi = (params) => {
    return service.get('/gameApi/message/list', {params})
}

//获取消息详情
export let getMessageDetailApi = (params) => {
    return service.get('/gameApi/message/detail', {params})
}

//获取预期返水
export let getExpectantInterestApi = (params) => {
    return service.get('/gameApi/getExpectantInterest', {params})
}

//获取我的返水比例
export let getUserRatioApi = (params) => {
    return service.get('/gameApi/user/ratio', {params})
}

//获取实际返水
export let getRealInterestApi = (params) => {
    return service.get('/gameApi/getRealInterest', {params})
}

//绑定资料
export let bindProfileApi = (params) => {
    return service.post('/gameApi/bind/profile', params)
}


const _config = {
    Headers: {
        'Content-Type': "multipart/form-data"
    }
}
// 上传接口
export let uploadApi = (params) => {
    return service.post("/gameApi/upload", params, _config);
};

//获取用户银行卡
export let getUserBankApi = (params) => {
    return service.get('/gameApi/getUserBank', params)
}

//获取支付标签
export let getPayTagApi = (params) => {
    return service.get('/v1/gameApi/pay/tag', params)
}

//获取支付渠道
export let getPayChannelApi = (params) => {
    return service.get('/gameApi/pay/channel', params)
}

//支付下单
export let gameApiPayApi = (params) => {
    return service.post('/gameApi/pay', params)
}

//获取场馆信息
export let getGameTagVenueApi = (params) => {
    return service.get('/v1/gameApi/game/tag/venue', params)
}

//一键回收
export let gameTransAllInApi = (params) => {
    return service.post('/v1/gameApi/game/transAll/in', params)
}

//添加收款账号
export let addUserBank = (params) => {
    return service.post('/gameApi/addUserBank', params)
}

//获取推广链接
export let getInviteUrlApi = (params) => {
    return service.get('/gameApi/getInviteUrl', params)
}

//取款
export let cashOutApi = (params) => {
    return service.post('/gameApi/cashOut', params)
}

//修改登录密码
export let modifyPassApi = (params) => {
    return service.post('/gameApi/modifyPass', params)
}

//设置取款密码
export let setPinCodeApi = (params) => {
    return service.post('/gameApi/setPinCode', params)
}

//修改取款密码
export let modifyPinCodeApi = (params) => {
    return service.post('/gameApi/modifyPinCode', params)
}

//提回平台
export let gameTransInApi = (params) => {
    return service.post('/v1/gameApi/game/trans/in', params)
}

//转入游戏
export let gameTransOutApi = (params) => {
    return service.post('/v1/gameApi/game/trans/out', params)
}

//查询用户平台余额
export let gameUserBalanceApi = (params) => {
    return service.get('/v1/gameApi/game/user/balance', params)
}

//查询玩家最后一次转入平台的余额
export let gameLastBalanceApi = (params) => {
    return service.get('/v1/gameApi/game/last/balance', params)
}

//获取取款标签
export let getTellerTagApi = (params) => {
    return service.get('/v1/gameApi/teller/tag', params)
}

//获取游戏列表
export let gameListApi = (params) => {
    return service.get('/v1/gameApi/game/list', {params})
}

//新增收藏游戏
export let gameFavourAddApi = (params) => {
    return service.post('/gameApi/game/favour/add', params)
}

//删除收藏游戏
export let gameFavourDeleteApi = (params) => {
    return service.post('/gameApi/game/favour/delete', params)
}

//获取收藏游戏
export let gameFavourListApi = (params) => {
    return service.get('/gameApi/game/favour/list', {params})
}

