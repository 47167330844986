<template>
    <div class="container w100 marginAuto">
        <div class="notice">
            <div class="w100 flex alignItemsCenter borderBox">
                <img style="width:51px;height: 49px;margin-right:15px;" src="/images/home/ad_img.png" alt="">
                <div class="flex1 h100 flex alignItemsCenter">
                    <marquee>{{platformInfo.message || '棋牌游戏、电子竞技等多种产品。祝您盈利多多！'}}</marquee>
                </div>
                <template v-if="isLogin(false)">
                    <div @click="$router.push({path:'/userCenter/messageCenter'})"
                         class="allBtn transformScale098 colorfff flex alignItemsCenter justifyContentCenter">
                        <span>全部</span>
                    </div>
                </template>

            </div>
        </div>
        <div class="recently flex">
            <div class="h100 flex alignItemsCenter">
                <img style="width: 23px;height: 23px;margin-right: 5px;" src="/images/home/shijian.png" alt="">
                <span class="font18 color333">最近在玩</span>
            </div>
            <div class="flex1 h100 flex alignItemsCenter justifyContentSpaceAround">
                <img @click="routeTo(item)" v-for="(item,index) in recentlyList" :key="index"
                     :src="`/images/home/zj_${item.icon}.png`"
                     class="transformScale098"
                     style="width:242px;" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: "notice",
        computed: {...mapState(['platformInfo', 'navBarInfo'])},
        data() {
            const recentlyList = [
                {icon: 'caipiao', url: '/webView/lottery', name: 'lottery'},
                {icon: 'qipai', url: '/webView/boardcard', name: 'boardcard'},
                {icon: 'dianjing', url: '/webView/esport', name: 'esport'},
                {icon: 'tiyu', url: '/webView/sport', name: 'sport'}
            ]
            return {
                recentlyList
            }
        },
        methods: {
            routeTo(item) {

                if (!item.name) return
                let _currentClick = this.navBarInfo.filter(l => l.name === item.name)

                //未找到对应数据则返回
                if (_currentClick.length === 0) return;
                const _lobby = _currentClick[0]['lobby']
                //lobby是不是 0 ，如果是0 ,就直接进入游戏
                //如果lobby是1 就 进入游戏列表

                let _fn = () => {
                    this.$router.push({path: item.url})
                }

                if (_lobby === 0) {
                    this.loginGame(_currentClick[0], _fn)
                    return
                }

                if (_lobby === 1) {
                    _fn()
                    return
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        .notice {
            height: 70px;
            background: url("@images/home/ad_bg.png") no-repeat center center/cover;

            > div {
                height: 55px;
                padding: 0px 26px;
            }

            .allBtn {
                width: 100px;
                height: 35px;
                margin-left: 15px;
                background: url("@images/home/quanbu_btn.png") no-repeat center bottom/100% 100%;
            }
        }

        .recently {
            height: 105px;
            padding-left: 43px;
            background: url("@images/home/zjzw_bg.png") no-repeat center center/cover;
        }

    }
</style>
